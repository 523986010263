.date-picker{
   // border: 1px solid black;
   display: flex;
   background-color: rgb(103,97,97,0.4);
   height: 56px;
   outline: 1px solid #676161 !important;
   border-radius: 4px;
   width: 100%;
   display: flex;
  
  
   /* To remove the line in the input */
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before,
  .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after,
   .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::before,
   .e-calendar-yellow.e-input-group.e-control-wrapper.e-date-wrapper::after,
   .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
   .e-calendar-yellow.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
   .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
   .e-calendar-yellow.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
   background: none !important;
   border: none !important;
   text-decoration: none !important;
   transition: none !important;
   padding: 2px 0px 0px 0px!important;
   margin: 0px !important;
   // margin-bottom: 0px !important;
   }
  
  
   input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection {
   background: none !important;
   color: white !important;
   }
  
   .e-input-focus::after{
   // border: 1px solid #f1a248 !important;
   // border: none !important;
   text-decoration: none !important;
   margin: 0px !important;
   padding: 0px 0px 0px 0px!important;
   }
   .e-input-focus::before{
   // border: none !important;
   text-decoration: none !important;
   margin: 0px !important;
   padding: 0px 0px 0px 0px!important;
   }
  
   .e-input-group-icon::before{
   color: white !important;
   margin: 0px !important;
   padding: 0px !important;
   text-decoration: none !important;
   }
  
   /* input date styling */
   .e-input{
   color: white !important;
   font-size: 16px !important;
   padding: 16px !important;
   background-color: none !important;
   // line-height: 24px;
   // text-decoration: none !important;
   // border: 1px solid black !important;
   }
   .e-input-group-icon{
   padding-right: 8px !important;
   padding-top: 2px !important;
   }
  
   .e-input-group.e-input-group.e-control-wrapper{
   margin-bottom: 0px !important;
   padding-top:2px !important;
   }
   .e-input-group.e-error.e-input-group.e-control-wrapper.e-error.e-input-group.e-error:not(.e-float-icon-left).e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
   border: none !important;
   }
  
   .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
   background: none !important;
   }
  
   .e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
   border-width: 0 0 0px 0 !important;
   }
  }
  
  .e-clear-icon{
  margin-right: 10px !important;
  }

  .date-picker::placeholder {
      color: #CCCCCC !important;
   }

   .date-picker input::placeholder {
      color: #CCCCCC !important;
   }