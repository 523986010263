@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

@font-face {
  font-family: 'elatina';
  src: url('../fonts/elatina.otf') format('opentype'),
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Bold';
  src: url('../fonts/axiata_bold.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Book';
  src: url('../fonts/axiata_book.otf') format('opentype');
  /* Add additional font formats if needed */
}

@font-face {
  font-family: 'Axiata Medium';
  src: url('../fonts/axiata_medium.otf') format('opentype');
  /* Add additional font formats if needed */
}

.custom-font {
  // font-family: 'elatina', sans-serif;
}

.headings-font {
  font-family: 'Axiata Bold', sans-serif;
}

.captions-font  {
  font-family: 'Axiata Book';
}

.captions-medium  {
  font-family: 'Axiata Medium';
}

#root{
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Axiata Medium', sans-serif;
}

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  z-index: 10;
}

.styled-scroll::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrl::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrl {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

:root{
  --toastify-color-success: #D1FAE5;
  --toastify-text-color-success: #334155;
  --toastify-text-color-error: #334155;
  --toastify-color-error: #FED7D7;
  --toastify-icon-color-success: #10B981 !important;
  --toastify-icon-color-error: #E53E3E !important;
}

.Toastify__toast--error{
  div{
    div{
      svg{
        color: red;
      }
    }
  }
}

.Toastify__toast-icon{
  color: #10B981;
}
.Toastify__close-button{
color: #334155;
}

.Toastify__toast-container {
font-size: 15px;
}